import { CommentApi, CommentUpdateApi } from 'api/models';
import * as io from 'io-ts';
import { DateTime } from 'luxon';
import { apiDecorator } from 'src/app/decorators';
import { decode } from 'src/app/utilities';

import { Client } from 'src/app/models/client/client.model';
import { Facility } from 'src/app/models/facility/facility.model';
import { UserBase } from 'src/app/models/user/user.model';

const api = apiDecorator<CommentApi>();

export class CommentBase {
  public constructor(props: ClassProperties<CommentBase>) {
    this.body = props.body;
    this.noteId = props.noteId;
    this.replyAt = props.replyAt;
  }

  @api({ key: 'body' }) public readonly body: string;
  @api({ key: 'noteId' }) public readonly noteId: number;
  @api({ key: 'replyAt' }) public readonly replyAt: DateTime;
}

export class Comment extends CommentBase {
  public constructor(props: ClassProperties<Comment>) {
    super(props);
    this.id = props.id;
    this.replyFrom = props.replyFrom;
    this.to = props.to;
  }

  /**
   * The io-ts codec for runtime type checking of the Comment model.
   */
  public static readonly Codec = io.type(
    {
      body: io.string,
      id: io.number,
      noteId: io.number,
      replyAt: io.string,
      replyFrom: UserBase.BaseCodec,
      to: Client.Codec,
    },
    'CommentApi',
  );

  @api({ key: 'id' }) public readonly id: number;
  @api({ key: 'replyFrom' }) public readonly replyFrom: UserBase;
  @api({ key: 'to' }) public readonly to: Client;

  /**
   * Deserializes a Comment object from the API model.
   *
   * @param value The value to deserialize.
   * @param deserializationArgs The deserialization arguments needed to
   * deserialize the object.
   * @returns The deserialized Comment object.
   * @throws An error if the value is not a valid Comment object.
   */
  public static deserialize(
    value: NonNullable<CommentApi>,
    deserializationArgs: CommentDeserializationArgs,
  ): Comment {
    const decoded = decode(Comment.Codec, value);
    return new Comment({
      ...decoded,
      replyAt: DateTime.fromISO(decoded.replyAt, {
        zone: deserializationArgs.facilityTimeZone,
      }),
      replyFrom: UserBase.deserialize(decoded.replyFrom),
      to: Client.deserialize(decoded.to, deserializationArgs),
    });
  }

  /**
   * Deserializes a list of Comment objects from the API model.
   *
   * @param values The values to deserialize.
   * @param deserializationArgs The deserialization arguments needed to
   * deserialize the object.
   * @returns The deserialized Comment objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not valid Comment
   * objects.
   */
  public static deserializeList(
    values: ReadonlyArray<NonNullable<CommentApi>>,
    deserializationArgs: CommentDeserializationArgs,
  ): readonly Comment[] {
    if (!Array.isArray(values)) {
      throw new Error('Expected array of Comment objects.');
    }
    return values.map((value) =>
      Comment.deserialize(value, { ...deserializationArgs }),
    );
  }
}

export class CommentUpdate extends CommentBase {
  public constructor(props: ClassProperties<CommentUpdate>) {
    super(props);

    this.replyFrom = props.replyFrom;
    this.to = props.to;
  }

  // Provide only what the API needs for `replyFrom` and `to`, the ID's.
  public readonly replyFrom: { id: UserBase['id'] };
  public readonly to: { id: UserBase['id'] };

  public serialize(): CommentUpdateApi {
    return {
      ...this,
      replyAt: this.replyAt.toISO(),
      replyFrom: {
        id: this.replyFrom.id,
      },
      to: {
        id: this.to.id,
      },
    };
  }
}

export interface CommentDeserializationArgs {
  facilityTimeZone: Facility['timeZone'];
}
