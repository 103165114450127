<div class="grid-item">
  <h4 *ngIf="client.fullName || '—' as fullName" [title]="fullName">
    <alleva-client-status-badge [client]="client"></alleva-client-status-badge>
    <span [innerText]="fullName" class="name"></span>
  </h4>
  <div class="grid-item-body">
    <div class="image-container">
      <img
        alt="Client Profile Image"
        [src]="client.profileImage | authBlob | async"
        [src-fallback]="'assets/images/clients/temp.png'"
      />
    </div>
    <dl>
      <div>
        <dt>DOB</dt>
        <dd *ngIf="(client.birthdate | date: 'MM/dd/yyyy') || '—' as birthdate">
          <span [title]="birthdate">{{ birthdate }}</span>
        </dd>
        <dt class="age">Age</dt>
        <dd *ngIf="client.age || '—' as age">
          {{ age }}
        </dd>
      </div>
      <div>
        <dt>Gender</dt>
        <dd *ngIf="client.gender || '—' as gender">
          <span [title]="gender">{{ gender }}</span>
        </dd>
      </div>
      <div>
        <dt>LOC</dt>
        <dd
          *ngIf="client.levelOfCareNames?.join(', ') || '—' as levelOfCareNames"
        >
          <span [title]="levelOfCareNames">{{ levelOfCareNames }}</span>
        </dd>
      </div>
      <div>
        <dt>Bed</dt>
        <dd *ngIf="client.activeBed?.name || '—' as activeBedName">
          <span [title]="activeBedName">{{ activeBedName }}</span>
        </dd>
      </div>
      <div>
        <dt>Admitted</dt>
        <dd
          *ngIf="
            (client.admissionDateTime | dateTime: 'MM/dd/yyyy, hh:mm a') ||
            '—' as admissionDateTime
          "
        >
          <span
            [title]="
              client.admissionDateTime | dateTime: 'MM/dd/yyyy, hh:mm a (ZZZZ)'
            "
            >{{ admissionDateTime }}</span
          >
        </dd>
      </div>
      <div>
        <dt>Discharged</dt>
        <dd
          *ngIf="
            (client.dischargeDateTime | dateTime: 'MM/dd/yyyy, hh:mm a') ||
            '—' as dischargedDateTime
          "
        >
          <span
            [title]="
              client.dischargeDateTime | dateTime: 'MM/dd/yyyy, hh:mm a (ZZZZ)'
            "
            >{{ dischargedDateTime }}</span
          >
        </dd>
      </div>
      <div>
        <dt>MRN</dt>
        <dd *ngIf="client.medicalRecordId || '—' as medicalRecordId">
          <span [title]="medicalRecordId">{{ medicalRecordId }}</span>
        </dd>
      </div>
    </dl>
  </div>
</div>
