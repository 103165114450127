import { DateTime } from 'luxon';
import {
  BehaviorSubject,
  combineLatest,
  firstValueFrom,
  map,
  switchMap,
} from 'rxjs';
import { NotePriorityEnum } from 'src/app/enumerators';
import {
  Client,
  Note,
  NoteType,
  NoteUpdate,
  NoteUser,
  Time,
} from 'src/app/models';
import {
  AlertService,
  AuthenticationService,
  ClientsRequestParam,
  ClientsService,
  FacilityService,
  NotesService,
} from 'src/app/services';
import {
  getEnumValues,
  isNonEmptyString,
  keyComparator,
} from 'src/app/utilities';

import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';

import { ButtonComponent } from 'src/app/components/button/button.component';
import { DialogConfig } from 'src/app/components/dialogs/dialog-config';
import { DialogRef } from 'src/app/components/dialogs/dialog-ref';
import { FormDirective } from 'src/app/components/forms/form.directive';
import { InputAutofillModule } from 'src/app/components/forms/input-autofill/input-autofill.module';
import { InputDropdownModule } from 'src/app/components/forms/input-dropdown/input-dropdown.module';
import { InputEditorModule } from 'src/app/components/forms/input-editor/input-editor.module';
import { InputPickDateModule } from 'src/app/components/forms/input-pick-date/input-pick-date.module';
import { InputPickTimeModule } from 'src/app/components/forms/input-pick-time/input-pick-time.module';
import { InputSignMeModule } from 'src/app/components/forms/input-sign-me/input-sign-me.module';
import { InputModule } from 'src/app/components/forms/input/input.module';
import { IconModule } from 'src/app/components/icon/icon.module';

@Component({
  selector: 'alleva-add-note-form-dialog',
  templateUrl: './add-note-form-dialog.component.html',
  styleUrls: [
    '../../forms/form.scss', // Default styles for all form dialogs
    './add-note-form-dialog.component.scss',
  ],
  providers: [ClientsService, NotesService],
  standalone: true,
  imports: [
    ButtonComponent,
    CommonModule,
    FormsModule,
    IconModule,
    InputAutofillModule,
    InputDropdownModule,
    InputEditorModule,
    InputModule,
    InputPickDateModule,
    InputPickTimeModule,
    InputSignMeModule,
    ReactiveFormsModule,
  ],
})
export class AddNoteFormDialogComponent
  extends FormDirective<AddNoteFormGroup>
  implements OnInit
{
  public constructor(
    private readonly alertService: AlertService,
    private readonly authenticationService: AuthenticationService,
    private readonly clientsService: ClientsService,
    private readonly config: DialogConfig<AddNoteFormInput>,
    private readonly dialog: DialogRef<Note | undefined>,
    private readonly facilityService: FacilityService,
    private readonly notesService: NotesService,
  ) {
    super();
  }

  protected isSubmitting = false;
  protected override readonly formGroup = formGroup;

  private readonly client = this.config.data?.client ?? null;
  private readonly now = DateTime.local();

  /** Clients filter subject. */
  private readonly clientsFilterSubject = new BehaviorSubject<string | null>(
    null,
  );

  protected readonly clientListChanges = combineLatest([
    this.clientsFilterSubject,
    this.facilityService.currentFacilityChanges,
  ]).pipe(
    switchMap(([value, currentFacility]) => {
      const params: ClientsRequestParam[] = [
        { key: 'facilityId', value: currentFacility.id },
        { key: 'pageNumber', value: '1' },
        { key: 'pageSize', value: '10' },
      ];
      if (isNonEmptyString(value)) {
        params.push({ key: 'search', value });
      }
      return this.clientsService
        .getPagedList(params)
        .pipe(map((clientPagedList) => clientPagedList?.results));
    }),
  );

  protected readonly clientNoteTypesChanges = this.notesService.getNoteTypes([
    { key: 'order', value: 'name' },
    { key: 'active', value: true },
  ]);

  protected readonly notePriorities = getEnumValues(NotePriorityEnum);

  public ngOnInit(): void {
    // Reset the form to its initial state just in case this dialog is reused.
    this.formGroup.reset();

    // Set the initial values for the form.
    this.formGroup.patchValue({
      client: this.client ?? null,
      date: this.now,
      time: Time.fromDateTime(this.now),
    });

    if (this.client) {
      // Client is provided and preset, so disable the client field.
      this.formGroup.controls.client.disable();
    } else {
      // Client is not provided, so enable the client field.
      this.formGroup.controls.client.enable();
    }
  }

  protected close(result?: Note): void {
    this.formGroup.reset();
    this.dialog.close(result);
  }

  protected filterClients(filter: string | null): void {
    this.clientsFilterSubject.next(filter);
  }

  /**
   * Gets the display data string from the provided values.
   *
   * @param value The value to pull the display data from.
   * @returns The display data string.
   */
  protected getDisplayValue(value: Client | null): string {
    return value?.fullName || '';
  }

  protected readonly idComparator = (
    value: Client,
    newValue: Client | null,
  ): boolean => keyComparator(value, newValue, 'id');

  protected async save(): Promise<void> {
    if (this.formGroup.invalid) {
      this.highlightInvalidControls();
      this.scrollToFirstInvalidControl();
      this.alertService.error({
        message: 'Please correct the displayed form errors.',
      });
      return;
    }

    // Disable the form while we're submitting.
    this.formGroup.disable();
    this.isSubmitting = true;

    const user = await firstValueFrom(this.authenticationService.userChanges);
    const { body, client, date, noteType, priority, signature, time } =
      this.formGroup.value;

    // Ensure all required values are present.
    if (!client || !date || !noteType || !priority || !time) {
      throw new Error('Form is invalid and validation failed on submit.');
    } else if (!user) {
      throw new Error(
        'Unauthenticated user is attempting to submit form data.',
      );
    }

    // Use the `date` from the date field and the `time` from the time field to
    // create a new `DateTime` object.
    const dateTime = time.toDateTime({
      day: date.day,
      month: date.month,
      year: date.year,
    });

    const newNote = new NoteUpdate({
      body: body ?? null,
      createdDate: DateTime.local(),
      date: dateTime,
      facility: client.facility,
      isFavorite: false,
      isSigned: !!signature,
      noteFrom: new NoteUser({
        firstName: user.firstName,
        id: user.id,
        lastName: user.lastName,
      }),
      noteTo: new NoteUser({
        firstName: client.name.first,
        id: client.id,
        lastName: client.name.last,
      }),
      priority,
      replies: 0,
      signature: signature ?? null,
      type: noteType,
    });

    const currentFacility = await firstValueFrom(
      this.facilityService.currentFacilityChanges,
    );

    // Update the saved note from API response, which includes the new ID.
    const savedNote = await firstValueFrom(
      this.notesService.postClientNote(newNote, {
        facilityTimeZone: currentFacility.timeZone,
      }),
    );

    // Re-enable the form now that we're done submitting.
    this.formGroup.enable();
    this.isSubmitting = false;

    if (savedNote) {
      this.alertService.error({
        message: 'Note saved successfully.',
      });
    } else {
      this.alertService.error({
        message: 'Failed to saved note. Please try again or contact support.',
      });
      return;
    }

    // Pass the new note back to the parent component to update reactively.
    this.close(savedNote);
  }
}

export interface AddNoteFormInput {
  /**
   * When the Client is provided the form will be pre-set to send to the
   * client and the client field will be disabled.
   */
  client?: Client;
}

interface AddNoteFormGroup {
  body: FormControl<Note['body'] | null>;
  client: FormControl<Client | null>;
  date: FormControl<Note['date'] | null>;
  noteType: FormControl<Note['type'] | null>;
  priority: FormControl<Note['priority'] | null>;
  signature: FormControl<NoteUpdate['signature'] | null>;
  time: FormControl<Time | null>;
}

const formGroup: FormGroup<AddNoteFormGroup> = new FormGroup({
  body: new FormControl<string | null>(null),
  client: new FormControl<Client | null>(null, Validators.required),
  date: new FormControl<DateTime | null>(null, Validators.required),
  noteType: new FormControl<NoteType | null>(null, Validators.required),
  priority: new FormControl<NotePriorityEnum | null>(null, Validators.required),
  signature: new FormControl<string | null>(null),
  time: new FormControl<Time | null>(null, Validators.required),
});
