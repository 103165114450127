import { coerceElement } from '@angular/cdk/coercion';
import { Component, ElementRef, Input } from '@angular/core';

@Component({
  selector: 'alleva-input-chip[value]',
  template: `<ng-content></ng-content>`,
})
export class InputChipComponent<T> {
  public constructor(private readonly elementRef: ElementRef<HTMLElement>) {}

  @Input() public set value(value: T) {
    this.#value = value;
  }
  public get value(): T {
    return this.#value;
  }
  #value!: T;

  public get element(): HTMLElement {
    return coerceElement(this.elementRef);
  }
}
