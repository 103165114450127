import { AllergenReactionApi } from 'api/models';
import * as io from 'io-ts';
import { apiDecorator } from 'src/app/decorators';
import { decode } from 'src/app/utilities';

const api = apiDecorator<AllergenReactionApi>();

export class AllergenReaction {
  public constructor(props: ClassProperties<AllergenReaction>) {
    this.id = props.id;
    this.name = props.name;
  }

  /**
   * The io-ts codec for runtime type checking of the Allergen Reaction API
   * model.
   */
  public static readonly Codec = io.type(
    {
      id: io.number,
      name: io.union([io.string, io.null]),
    },
    'AllergenReactionApi',
  );

  @api({ key: 'id' }) public readonly id: number;
  @api({ key: 'name' }) public readonly name: string | null;

  /**
   * Deserializes a Allergen Reaction object from an API model.
   *
   * @param value The value to deserialize.
   * @returns The deserialized Allergen Reaction object.
   * @throws An error if the value is not a valid Allergen Reaction object.
   */
  public static deserialize(
    value: NonNullable<AllergenReactionApi>,
  ): AllergenReaction {
    const decoded = decode(AllergenReaction.Codec, value);
    return new AllergenReaction(decoded);
  }

  /**
   * Deserializes a list of Allergen Reaction objects from an API model.
   *
   * @param values The values to deserialize.
   * @returns The deserialized Allergen Reaction objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not valid Allergen Reaction
   * objects.
   */
  public static deserializeList(
    values: ReadonlyArray<NonNullable<AllergenReactionApi>>,
  ): readonly AllergenReaction[] {
    if (!Array.isArray(values)) {
      throw new Error('Expected array of Allergen Reaction objects.');
    }
    return values.map(AllergenReaction.deserialize);
  }
}
