import { UserBase } from 'src/app/models';

import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatIconModule } from '@angular/material/icon';

import { DirectivesModule } from 'src/app/directives/directives.module';

import { PipesModule } from 'src/app/pipes/pipes.module';

/**
 * A component that displays a user data card.
 *
 * @requires `user` attribute to be set.
 */
@Component({
  selector: 'alleva-user-card[user]',
  templateUrl: './user-card.component.html',
  styleUrls: ['../card.scss'],
  /** Standalone Options */
  standalone: true,
  imports: [
    CommonModule,
    DirectivesModule,
    MatBadgeModule,
    MatIconModule,
    PipesModule,
  ],
})
export class UserCardComponent {
  /**
   * The user data to display.
   */
  @Input() public user!: UserBase;
}
