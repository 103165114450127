@if (chips && chips.length) {
  <mat-label>{{ label }}</mat-label>
  <mat-chip-listbox
    [compareWith]="compareWith"
    [formControl]="baseControl"
    [multiple]="multiple"
  >
    @for (chip of chips; track chip) {
      <mat-chip-option [value]="chip.value">{{
        chip.element.textContent
      }}</mat-chip-option>
    }
  </mat-chip-listbox>
  <mat-error *ngIf="baseControl.invalid && baseControl.touched">
    @if (baseControl.hasError('required')) {
      {{ label }} is required.
    } @else if (baseControl.hasError('min')) {
      {{ label }} must be at least {{ baseControl.errors?.['min'].min }}.
    } @else if (baseControl.hasError('max')) {
      {{ label }} cannot exceed {{ baseControl.errors?.['max'].max }}.
    } @else if (baseControl.hasError('minlength')) {
      {{ label }} must be at least
      {{ baseControl.errors?.['minlength'].requiredLength }} characters.
    } @else if (baseControl.hasError('maxlength')) {
      {{ label }} cannot exceed
      {{ baseControl.errors?.['maxlength'].requiredLength }} characters.
    }
  </mat-error>
} @else {
  <alleva-loading-text [label]="label" type="input"></alleva-loading-text>
}
