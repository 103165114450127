import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  Component,
  ContentChildren,
  Input,
  OnInit,
  QueryList,
} from '@angular/core';
import { MatChipListbox } from '@angular/material/chips';

import { CustomInputDirective } from 'src/app/components/forms/custom-input.directive';

import { InputChipComponent } from './input-chip.component';

/**
 * A custom form component that allows the user to select a single, or multiple
 * chips from a list.
 *
 * @requires `formControl` attribute to be set.
 * @requires `label` attribute to be set.
 */
@Component({
  selector: 'alleva-input-chips[formControl][label]',
  templateUrl: './input-chips.component.html',
  styleUrls: ['./input-chips.component.scss'],
})
export class InputChipsComponent<T>
  extends CustomInputDirective<T>
  implements OnInit
{
  @ContentChildren(InputChipComponent)
  public readonly chips: QueryList<InputChipComponent<T>> = new QueryList<
    InputChipComponent<T>
  >();

  /**
   * The _required_ label for this input.
   */
  @Input() public override label!: string;

  /**
   * The maximum number of chips that can be selected
   */
  @Input() public override max: number = 1;

  /**
   * Whether the user should be allowed to select multiple chips.
   */
  @Input() public set multiple(value: BooleanInput) {
    this.#multiple = coerceBooleanProperty(value);
  }
  public get multiple(): boolean {
    return this.#multiple;
  }
  #multiple = false;

  public override ngOnInit(): void {
    super.ngOnInit();

    // Validate integrity of the component.
    if (!this.chips) {
      throw new Error('InputChipsComponent requires chips to be set.');
    } else if (!this.label) {
      throw new Error('InputChipsComponent requires label to be set.');
    }
  }

  /**
   * Function to compare the option values with the selected values. The first argument
   * is a value from an option. The second is a value from the selection. A boolean
   * should be returned.
   */
  @Input() public compareWith: MatChipListbox['compareWith'] = (
    optionValue,
    selectedValue,
  ) => {
    return (
      JSON.stringify(optionValue) === JSON.stringify(selectedValue) ||
      optionValue === selectedValue
    );
  };
}
