import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { MatSlideToggle } from '@angular/material/slide-toggle';

import { CustomInputDirective } from 'src/app/components/forms/custom-input.directive';

/**
 * An input component for toggling a single option.
 *
 * @requires `formControl` attribute to be set.
 */
@Component({
  selector: 'alleva-input-toggle[formControl]',
  templateUrl: './input-toggle.component.html',
  styleUrls: ['./input-toggle.component.scss'],
})
export class InputToggleComponent
  extends CustomInputDirective<boolean>
  implements OnInit
{
  @ViewChild(MatSlideToggle) public readonly matSlideToggle?: MatSlideToggle;

  /**
   * The color palette to use for the slide toggle.
   *
   * @default 'primary' Default color palette value.
   */
  @Input() public color: ThemePalette | 'success' = 'primary';

  public override ngOnInit(): void {
    super.ngOnInit();

    if (this.baseControl === undefined) {
      throw new Error('FormControl is required for InputToggleComponent');
    }
  }
}
