<div class="grid-item">
  <h4 *ngIf="referral.name.fullName || '—' as fullName" [title]="fullName">
    <span [innerText]="fullName" class="name"></span>
  </h4>
  <div class="grid-item-body">
    <div class="image-container">
      <img
        alt="Referral Profile Image"
        [src]="referral.image | authBlob | async"
        [src-fallback]="'assets/images/clients/temp.png'"
      />
    </div>
    <dl>
      <div>
        <dt>Email</dt>
        <dd>
          <span [title]="referral.email">{{ referral.email || '—' }}</span>
        </dd>
      </div>
      <div>
        <dt>Phone Number</dt>
        <dd>
          <span [title]="referral.phone.home">{{ referral.phoneDisplay }}</span>
        </dd>
      </div>
    </dl>
  </div>
</div>
