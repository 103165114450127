import { NgModule } from '@angular/core';

import { AttributesDirective } from 'src/app/directives/attributes.directive';
import { SrcFallbackDirective } from 'src/app/directives/fallback-src.directive';
import { HasPermissionDirective } from 'src/app/directives/has-permission.directive';

@NgModule({
  declarations: [
    AttributesDirective,
    HasPermissionDirective,
    SrcFallbackDirective,
  ],
  exports: [AttributesDirective, HasPermissionDirective, SrcFallbackDirective],
})
export class DirectivesModule {}
