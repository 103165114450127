<div class="grid-item">
  <h4 *ngIf="user.fullName || '—' as fullName" [title]="fullName">
    <span [innerText]="fullName" class="name"></span>
  </h4>
  <div class="grid-item-body">
    <div class="image-container">
      <img
        alt="User Profile Image"
        [src]="user.image | authBlob | async"
        [src-fallback]="'assets/images/clients/temp.png'"
      />
    </div>
    <dl>
      <div>
        <dt>Phone Number</dt>
        <dd *ngIf="user.phone || '—' as phone" [title]="phone">
          {{ phone }}
        </dd>
      </div>
      <div>
        <dt>Active</dt>
        <dd>
          {{ user.active | yesNo }}
        </dd>
      </div>
    </dl>
  </div>
</div>
