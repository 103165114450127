import {
  AfterViewChecked,
  AfterViewInit,
  Directive,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';

import { isCustomFormGroup } from 'src/app/components/forms/custom-form-group.directive';
import { InputArmPositionComponent } from 'src/app/components/forms/input-arm-position/input-arm-position.component';
import { InputAutofillComponent } from 'src/app/components/forms/input-autofill/input-autofill.component';
import { InputBloodPressureComponent } from 'src/app/components/forms/input-blood-pressure/input-blood-pressure.component';
import { InputChipsComponent } from 'src/app/components/forms/input-chips/input-chips.component';
import { InputCurrencyComponent } from 'src/app/components/forms/input-currency/input-currency.component';
import { InputDropdownComponent } from 'src/app/components/forms/input-dropdown/input-dropdown.component';
import { InputEditorComponent } from 'src/app/components/forms/input-editor/input-editor.component';
import { InputHeightComponent } from 'src/app/components/forms/input-height/input-height.component';
import { InputPickDateComponent } from 'src/app/components/forms/input-pick-date/input-pick-date.component';
import { InputPickFilesComponent } from 'src/app/components/forms/input-pick-files/input-pick-files.component';
import { InputPickListComponent } from 'src/app/components/forms/input-pick-list/input-pick-list.component';
import { InputPickTimeComponent } from 'src/app/components/forms/input-pick-time/input-pick-time.component';
import { InputRadioComponent } from 'src/app/components/forms/input-radio/input-radio.component';
import { InputSignMeComponent } from 'src/app/components/forms/input-sign-me/input-sign-me.component';
import { InputTemperatureComponent } from 'src/app/components/forms/input-temperature/input-temperature.component';
import { InputToggleComponent } from 'src/app/components/forms/input-toggle/input-toggle.component';
import { InputWeightComponent } from 'src/app/components/forms/input-weight/input-weight.component';
import { InputComponent } from 'src/app/components/forms/input/input.component';

/**
 * A common form directive for all forms in the application to extend from and
 * inherit common functionality.
 */
@Directive()
export class FormDirective<T extends { [K in keyof T]: AbstractControl }>
  implements AfterViewInit, AfterViewChecked
{
  protected readonly formGroup: FormGroup<T> | null = null;

  @ViewChildren(InputComponent)
  private readonly inputs!: QueryList<InputComponent<unknown>>;

  @ViewChildren(InputArmPositionComponent)
  private readonly inputsArmPosition!: QueryList<InputArmPositionComponent>;

  @ViewChildren(InputAutofillComponent)
  private readonly inputAutofill!: QueryList<InputAutofillComponent<object>>;

  @ViewChildren(InputBloodPressureComponent)
  private readonly inputsBloodPressure!: QueryList<InputBloodPressureComponent>;

  @ViewChildren(InputChipsComponent)
  private readonly inputsChips!: QueryList<InputChipsComponent<unknown>>;

  @ViewChildren(InputCurrencyComponent)
  private readonly inputsCurrency!: QueryList<InputBloodPressureComponent>;

  @ViewChildren(InputDropdownComponent)
  private readonly inputsDropdown!: QueryList<InputDropdownComponent<unknown>>;

  @ViewChildren(InputEditorComponent)
  private readonly inputsEditor!: QueryList<InputEditorComponent>;

  @ViewChildren(InputHeightComponent)
  private readonly inputsHeight!: QueryList<InputHeightComponent>;

  @ViewChildren(InputPickDateComponent)
  private readonly inputsPickDate!: QueryList<InputPickDateComponent>;

  @ViewChildren(InputPickFilesComponent)
  private readonly inputsPickFiles!: QueryList<InputPickFilesComponent>;

  @ViewChildren(InputPickListComponent)
  private readonly inputsPickList!: QueryList<InputPickListComponent<object>>;

  @ViewChildren(InputPickTimeComponent)
  private readonly inputsPickTime!: QueryList<InputPickTimeComponent>;

  @ViewChildren(InputRadioComponent)
  private readonly inputsRadio!: QueryList<InputRadioComponent<unknown>>;

  @ViewChildren(InputSignMeComponent)
  private readonly inputsSignMe!: QueryList<InputSignMeComponent>;

  @ViewChildren(InputTemperatureComponent)
  private readonly inputsTemperature!: QueryList<InputTemperatureComponent>;

  @ViewChildren(InputToggleComponent)
  private readonly inputsToggle!: QueryList<InputToggleComponent>;

  @ViewChildren(InputWeightComponent)
  private readonly inputsWeight!: QueryList<InputWeightComponent>;

  /**
   * All the possible form inputs in the form. Update these when adding new
   * inputs types of custom inputs/controls forms that extend this class.
   */
  private formInputs: Array<
    | InputArmPositionComponent
    | InputAutofillComponent<object>
    | InputBloodPressureComponent
    | InputChipsComponent<unknown>
    | InputComponent<unknown>
    | InputCurrencyComponent
    | InputDropdownComponent<unknown>
    | InputEditorComponent
    | InputHeightComponent
    | InputPickDateComponent
    | InputPickFilesComponent
    | InputPickListComponent<object>
    | InputPickTimeComponent
    | InputRadioComponent<unknown>
    | InputSignMeComponent
    | InputTemperatureComponent
    | InputToggleComponent
    | InputWeightComponent
  > = [];

  public ngAfterViewInit(): void {
    if (!this.formGroup) {
      throw new Error('You must provide a `FormGroup` to the form directive!');
    }
    this.refreshFormInputs();
  }

  public ngAfterViewChecked(): void {
    this.refreshFormInputs();
  }

  /**
   * Trigger validation checks on the fields which will display red borders,
   * error messages, etc. by marking all invalid controls as touched.
   */
  public highlightInvalidControls(): void {
    if (this.formInputs.length === 0) {
      throw new Error('No form inputs provided to highlight!');
    }

    for (const input of this.formInputs) {
      if (!input.baseControl) {
        throw new Error(
          `Input "${
            input.label ?? String(input)
          }" does not have a base control!`,
        );
      }
      if (input.baseControl.invalid) {
        input.baseControl.markAsTouched();

        // Mark sub-controls as touched.
        if (input instanceof InputPickTimeComponent) {
          input.markAsTouched();
          return;
        }

        // Mark all the controls in the custom form group as touched.
        if (isCustomFormGroup(input)) {
          input.markAllAsTouched();
        }
      }
    }
  }

  /** Scroll to the first invalid control in the form for the user to see. */
  public scrollToFirstInvalidControl(): void {
    if (this.formInputs.length === 0) {
      throw new Error('No form inputs provided to scroll to!');
    }

    // Scroll to the element that is invalid.
    for (const input of this.formInputs) {
      if (!input.baseControl) {
        throw new Error(
          `Input "${
            input.label ?? String(input)
          }" does not have a base control!`,
        );
      }
      if (input.baseControl.invalid) {
        input.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
        // We only need to run this until we find the first invalid input.
        break;
      }
    }
  }

  private refreshFormInputs(): void {
    this.formInputs = [
      ...this.inputs.toArray(),
      ...this.inputsArmPosition.toArray(),
      ...this.inputsBloodPressure.toArray(),
      ...this.inputsChips.toArray(),
      ...this.inputsCurrency.toArray(),
      ...this.inputsDropdown.toArray(),
      ...this.inputAutofill.toArray(),
      ...this.inputsEditor.toArray(),
      ...this.inputsHeight.toArray(),
      ...this.inputsPickDate.toArray(),
      ...this.inputsPickFiles.toArray(),
      ...this.inputsPickList.toArray(),
      ...this.inputsPickTime.toArray(),
      ...this.inputsRadio.toArray(),
      ...this.inputsSignMe.toArray(),
      ...this.inputsTemperature.toArray(),
      ...this.inputsToggle.toArray(),
      ...this.inputsWeight.toArray(),
    ];
  }
}
