import {
  AllergenApi,
  AllergenPagedListApi,
  AllergenReactionApi,
  AllergenTypeApi,
} from 'api/models';
import * as io from 'io-ts';
import { Observable, catchError, map, of } from 'rxjs';
import { AllergenSeverityEnum, SortOrderEnum } from 'src/app/enumerators';
import {
  Allergen,
  AllergenType,
  NameId,
  allergySeverityEnumCodec,
} from 'src/app/models';
import { decode, isNonEmptyValue, parseHttpParams } from 'src/app/utilities';
import { config } from 'src/configs/config';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

/**
 * A service for interacting with the allergies API.
 */
@Injectable()
export class AllergiesService {
  public constructor(private readonly httpClient: HttpClient) {}

  /**
   * Fetch and return the paged data for all allergens.
   *
   * @param requestParameters The request parameters to use for the API call.
   * @returns All paged allergens model data on success, undefined on error.
   */
  public getPagedList(
    requestParameters?: readonly AllergenRequestParam[],
  ): Observable<AllergenPagedList | undefined> {
    const params = parseHttpParams(requestParameters);
    return this.httpClient
      .get<AllergenPagedListApi | undefined>(`${config.api.url}/allergens`, {
        params,
      })
      .pipe(
        map((response) =>
          response
            ? {
                ...decode(AllergenPagedListCodec, response),
                results: Allergen.deserializeList(response.results),
              }
            : undefined,
        ),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }

  /**
   * Fetch the allergy reactions list from the API.
   *
   * @param requestParameters The request parameters to use for the API call.
   * @returns The list of allergy reactions on success, undefined on error.
   */
  public getAllergyReactionsList(
    requestParameters?: readonly AllergenRequestParam[],
  ): Observable<readonly NameId[] | undefined> {
    const params = parseHttpParams(requestParameters);
    return this.httpClient
      .get<readonly AllergenReactionApi[]>(
        `${config.api.url}/allergens/reactions`,
        {
          params,
        },
      )
      .pipe(
        map((response) => NameId.deserializeList(response)),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }

  /**
   * Fetch the allergy severity list from the API.
   *
   * @returns The list of allergy reactions on success, undefined on error.
   */
  public getAllergySeverityList(): Observable<
    readonly AllergenSeverityEnum[] | undefined
  > {
    return this.httpClient
      .get<
        readonly AllergenSeverityEnum[] | undefined
      >(`${config.api.url}/allergens/severity`)
      .pipe(
        map((response) => {
          if (!response) {
            throw new Error(
              'Response for "Allergy Severities" GET is undefined.',
            );
          }

          const codec = io.array(allergySeverityEnumCodec);
          const decoded = decode(codec, response).filter(isNonEmptyValue);
          return decoded;
        }),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }

  /**
   * Fetch the allergy type list from the API.
   *
   * @returns The list of allergy type on success, undefined on error.
   */
  public getAllergyTypeList(): Observable<readonly AllergenType[] | undefined> {
    return this.httpClient
      .get<
        readonly AllergenTypeApi[] | undefined
      >(`${config.api.url}/allergens/type`)
      .pipe(
        map((response) => {
          if (!response) {
            throw new Error('Response for "Allergy Types" GET is undefined.');
          }
          return AllergenType.deserializeList(response);
        }),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }
}

const AllergenPagedListCodec = io.type(
  {
    currentCount: io.number,
    currentPage: io.number,
    pageSize: io.number,
    results: io.readonlyArray(Allergen.Codec),
    totalCount: io.number,
    totalPages: io.number,
  },
  'AllergenPagedListApi',
);

export interface AllergenPagedList {
  readonly currentCount: number;
  readonly currentPage: number;
  readonly pageSize: number;
  readonly results: readonly Allergen[];
  readonly totalCount: number;
  readonly totalPages: number;
}

export interface AllergenRequestParam extends RequestParameter {
  key: 'limit' | 'name' | 'order' | 'pageNumber' | 'pageSize' | 'sort';
  value:
    | keyof AllergenApi // For `sort`.
    | SortOrderEnum // For `order`.
    | string // For `name`.
    | number; // For `limit`, `pageNumber`, and `pageSize`.
}
