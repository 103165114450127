import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  Renderer2,
} from '@angular/core';

/**
 * A directive that hooks into all image elements and monitors for errors in
 * loading the image. If an error occurs, the directive will set the `src`
 * attribute to the value of the `src-fallback` input if provided.
 */
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'img[src-fallback]',
})
export class SrcFallbackDirective {
  public constructor(
    private readonly elementRef: ElementRef,
    private readonly renderer: Renderer2,
  ) {}

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('src-fallback') public srcFallback?: string;

  /**
   * A flag to indicate if the fallback has been applied. This is used to prevent
   * the fallback from being applied in an infinite loop if the fallback image
   * also fails to load.
   */
  private fallbackApplied = false;

  /**
   * Listen for the error event on the image element. If the event is triggered,
   * set the `src` attribute to the source fallback value.
   */
  @HostListener('error') protected onError(): void {
    if (this.srcFallback && !this.fallbackApplied) {
      this.fallbackApplied = true;
      this.renderer.setAttribute(
        this.elementRef.nativeElement,
        'src',
        this.srcFallback,
      );
    }
  }
}
